import React, { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import OwlCarousel from "react-owl-carousel";
import InputGroup from "react-bootstrap/InputGroup";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { Link, useParams } from "react-router-dom";
import sliderImg from "../assets/images/left-slide-icon.svg";
import Layout from "../Layout/Layout";
import { useDispatch, useSelector } from "react-redux";
import { getSalonProfileById } from "../redux/actions/userActions";
import InitMap from "../Components/Map/InitMap";
import { Encryptedid } from "../utils/BcruptEncyptid";
import { getAllReviews } from "../redux/actions/adminActions";
import { Rating } from "react-simple-star-rating";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { Helmet } from "react-helmet";
const options = {
  responsiveClass: true,
  nav: true,
  dots: false,
  autoplay: false,

  smartSpeed: 1000,

  responsive: {
    0: {
      items: 1,
    },
    400: {
      items: 1,
    },
    600: {
      items: 2,
    },
    700: {
      items: 2,
    },
    1000: {
      items: 5,
    },
  },
};

const optionsNewslider = {
  responsiveClass: true,
  nav: true,
  dots: false,
  autoplay: false,
  stagePadding: 10,
  smartSpeed: 1000,

  responsive: {
    0: {
      items: 1,
    },
    400: {
      items: 1,
    },
    600: {
      items: 1,
    },
    700: {
      items: 1,
    },
    1000: {
      items: 1,
    },
  },
};
var settings = {
  dots: false,
  nav: true,
  infinite: false,
  speed: 500,
  slidesToShow: 5,
  slidesToScroll: 1,

  responsive: [
    {
      breakpoint: 1300, // Breakpoint for tablet devices
      settings: {
        slidesToShow: 3, // Adjust the number of slides to show for tablets
      },
    },
    {
      breakpoint: 1024, // Breakpoint for tablet devices
      settings: {
        slidesToShow: 3, // Adjust the number of slides to show for tablets
      },
    },
    {
      breakpoint: 768, // Breakpoint for mobile devices
      settings: {
        slidesToShow: 2, // Adjust the number of slides to show for mobiles
      },
    },
    {
      breakpoint: 576, // Breakpoint for mobile devices
      settings: {
        slidesToShow: 1, // Adjust the number of slides to show for mobiles
      },
    },
  ],
};
export default function SalonDetail() {
  const BASE_URL = process.env.REACT_APP_HOST_NAME;
  const dispatch = useDispatch();
  const params = useParams();
  const { salonDetail, statics, subscription_data, reviews, totalReviews } =
    useSelector((state) => ({
      salonDetail: state.userMgmtSlice.salonDetail,
      statics: state.userMgmtSlice.statics,
      subscription_data: state.userMgmtSlice.subscription_data,
      reviews: state.userMgmtSlice.reviews,
      totalReviews: state.userMgmtSlice.totalReviews,
    }));

  useEffect(() => {
    setKey((prevKey) => prevKey + 1);
    dispatch(getSalonProfileById({ id: params.id }));
  }, [params.id]);

  const [search, setSearch] = useState("");
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [key, setKey] = useState(0); // Key to force re-render

  useEffect(() => {
    setKey((prevKey) => prevKey + 1);
    dispatch(
      getAllReviews({
        page: search ? 1 : page,
        limit: 10,
        search: "",
        user_type: 1,
        salonId: params.id,
      })
    );
  }, [params.id]);

  const handleBooking = (url) => {
    dispatch(getSalonProfileById({ id: params.id, booking: "booking" }));
    window.open(url, '_blank');
  };
  const appendHttps = (url) => {
    if (!url.startsWith('https://')) {
      return `https://${url}`;
    }
    return url;
  };

  console.log(salonDetail?.website_url, "salonDetail");

  const renderOpeningHours = (openingHours) => {
    if (!Array.isArray(openingHours) || openingHours?.length === 0) {
      return <p>No opening hours available</p>;
    }
  
    return openingHours.map((res, index) => {
      const dayInfo = res?._doc;
      const timeSlot = dayInfo?.arr[0];
  
      const isClosed =
        !timeSlot || // If `arr[0]` is undefined
        timeSlot?.reason === "Unavailable" || // If the reason is "Unavailable"
        (timeSlot?.start === "12:00 AM" && timeSlot?.end === "12:00 AM"); // If start and end indicate closed
  
      return (
        <p key={index}>
          <span>
            {dayInfo?.shortDay}:{" "}
            {isClosed
              ? "Closed"
              : `${timeSlot?.start || "N/A"} - ${timeSlot?.end || "N/A"}`}
          </span>
        </p>
      );
    });
  };

  return (
    <>
      <Helmet>
        <title>{salonDetail?.business_name || ""} - Salon Rated</title>
        <meta name="description" content={salonDetail?.business_desc} />
      </Helmet>
      <Layout>
        {/* Header section start  */}

        <section className="banner-img-salon">
          {/* top navbar start  */}

          {/* top navbar End  */}

          {/* bottom navbar start  */}
          <Container>
            <Row>
              <Col lg={12}>
                <div className="Salon-Detail-banner">
                  <img
                    src={
                      salonDetail?.profile_logo
                        ? BASE_URL + "/" + salonDetail?.profile_logo
                        : require("../assets/images/band-logo-icon.png")
                    }
                  />

                  <h3>{salonDetail?.business_name}</h3>

                  {salonDetail?.subscription >= 2 && (
                    <p>{salonDetail?.business_desc.slice(0, 400)}</p>
                  )}

                  <div className="Salon-Detail-banner-btn">
                    {salonDetail?.subscription >= 3 &&
                      salonDetail?.booking_url &&
                      salonDetail?.booking_url != "" && (
                        <button
                          onClick={() =>
                            handleBooking(salonDetail?.booking_url)
                          }
                        >
                          Book Now
                        </button>
                      )}
                    {salonDetail?.subscription == 3 &&
                      salonDetail?.website_url &&
                      salonDetail?.website_url != "" && (
                        <a href={appendHttps(salonDetail?.website_url)} target="_blank">
                          Visit Website
                        </a>
                      )}
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
          {/* bottom navbar start  */}
        </section>

        {/* Header section End */}

        {/* Salon detail contact start  */}
        <section className="Salon-detail-contact">
          <Container>
            <Row>
              <Col lg={12}>
                <Row className="justify-content-center">
                  {salonDetail?.subscription >= 2 && (
                    <>
                      <Col lg={4}>
                        <div className="contact-card-box">
                          <div className="icon-img">
                            <img
                              src={
                                require("../assets/images/contact-detail-icon.svg")
                                  .default
                              }
                            />
                          </div>
                          <h3 className="RecifeDisplay">Contact</h3>
                          <p className="Futura-Lt-BT">
                            Email - {salonDetail?.business_email}
                            <br />{" "}
                            {salonDetail?.phone_number &&
                              "Telephone - " + salonDetail?.phone_number}
                          </p>
                        </div>
                      </Col>
                      <Col lg={4}>
                        <div className="contact-card-box">
                          <div className="icon-img">
                            <img
                              src={
                                require("../assets/images/hours-detail-icon.svg")
                                  .default
                              }
                            />
                          </div>
                          <h3 className="RecifeDisplay">Hours</h3>
                          <p className="Futura-Lt-BT">
                            {/* {
                              Array.isArray(salonDetail?.openingHours?.opening_hours) && salonDetail?.openingHours?.opening_hours?.length > 0 && salonDetail?.openingHours?.opening_hours?.map((res, index) => {
                                return <>
                                  <p key={index + 1}>
                                    <span>{res?._doc?.shortDay}: {res?._doc?.arr[0]?.reason == ("Unavailable" || "") ? `Closed` : res?._doc?.arr[0]?.start.substring(0, 5) === "12:00" && res?._doc?.arr[0]?.end.substring(0, 5) === "12:00" ? `Closed` : `${res?._doc?.arr[0]?.start} - ${res?._doc?.arr[0]?.end}`}</span>
                                  </p>
                                </>
                              })
                            } */}
                            {renderOpeningHours(salonDetail?.openingHours?.opening_hours)}
                            {/* Mon-Fri: 9 AM - 6 PM
                            <br /> Sat-Sun: Closed */}
                          </p>
                        </div>
                      </Col>
                    </>
                  )}

                  <Col lg={4}>
                    <div className="contact-card-box">
                      <div className="icon-img">
                        <img
                          src={
                            require("../assets/images/address-detail-icon.svg")
                              .default
                          }
                        />
                      </div>
                      <h3 className="RecifeDisplay">Address</h3>
                      <p className="Futura-Lt-BT address-set">
                        {salonDetail?.address?.split(",")[0]}
                      </p>
                      <p className="Futura-Lt-BT address-set">
                        {salonDetail?.address?.split(",")[1]}
                      </p>
                      <p className="Futura-Lt-BT address-set">
                        {salonDetail?.address?.split(",")[2]}
                      </p>
                      <p className="Futura-Lt-BT">{salonDetail?.postcode}</p>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Container>
        </section>

        {/* Salon detail contact End  */}

        {/* Map section start  */}
        <section className="Map-section">
          <Container fluid>
            <Row>
              <Col lg={12} className="px-0">
                <div className="salon-map">
                  {salonDetail && <InitMap salonArr={[salonDetail]} />}
                </div>
              </Col>
            </Row>
          </Container>
        </section>

        {/* Map section end  */}

        {/* popular treatments  start */}

        <section className="popular-treatments bg-white">
          <Container>
            <Row>
              <Col lg={12}>
                <div className="treatment-head-txt">
                  <h2 className="RecifeDisplay common-heading">Treatments</h2>
                  <p>Swipe to see all treatments</p>
                </div>
                <div className="side-treatment mt-5">
                  <Slider {...settings} key={statics?.treatements[0]}>
                    {salonDetail?.treatments?.map((item, index) => {
                      const findImg = statics?.treatements?.find(
                        (data) => item == data.name
                      );
                      console.log(findImg, "findImg");
                      return (
                        <div class="item treatment-items" key={index}>
                          <div className="items-box">
                            <div className="treat-box-icon">
                              <img
                                src={
                                  findImg?.image
                                    ? BASE_URL + findImg?.image
                                    : require("../assets/images/nail-treat.webp")
                                }
                              />
                            </div>
                            <p>{item}</p>
                          </div>
                        </div>
                      );
                    })}
                  </Slider>
                </div>
                {/* <div className="side-treatment mt-5">
                  <OwlCarousel
                    key={statics?.treatements[0]}
                    className="owl-theme"
                    loop={true}
                    margin={10}
                    items={salonDetail?.treatments?.length}
                    {...options}
                    nav={true}
                  >
                    {salonDetail?.treatments?.map((item) => {
                      const findImg = statics?.treatements?.find(
                        (data) => item == data.name
                      );
                      console.log(findImg, "findImg");
                      return (
                        <div class="item treatment-items">
                          <div className="items-box">
                            <div className="treat-box-icon">
                              <img
                                src={
                                  findImg?.image
                                    ? BASE_URL + findImg?.image
                                    : require("../assets/images/nail-treat.webp")
                                }
                              />
                            </div>
                            <p>{item}</p>
                          </div>
                        </div>
                      );
                    })}
                  </OwlCarousel>
                </div> */}
              </Col>
            </Row>
          </Container>
        </section>

        {/* popular treatments  End */}

        {/* What our clients say start */}

        <section className="clients-say salon-detail-banner">
          <Container>
            <Row>
              <Col lg={6}></Col>
              <Col lg={6}>
                <div className="clients-say-right">
                  <p>Read about us</p>
                  <h2 className="RecifeDisplay common-heading">
                    What our clients say
                  </h2>
                </div>
                {reviews.length ? (
                  <div className="">
                    <OwlCarousel
                      key={reviews[0]?.name}
                      className="owl-theme"
                      loop
                      margin={10}
                      items={1}
                      nav={true}
                      {...optionsNewslider}
                    >
                      {reviews?.map((item) => {
                        return (
                          <>
                            <div class="item clients-say-comment">
                              <div className="clients-say-text">
                                <p className="Futura-Lt-BT">
                                  {item?.description}
                                </p>
                                <div className="clients-say-profile">
                                  {/* <img
                  className="user-img"
                  src={require("../assets/images/client-img.webp")}
                /> */}
                                  {/* <img
                  className="profile-img-bt"
                  src={
                    require("../assets/images/img-test-icon.svg")
                      .default
                  }
                /> */}
                                  <div className="clients-p-name">
                                    <h3>{item?.name}</h3>
                                    <p>{item?.address}</p>
                                    <Rating initialValue={item?.rating} />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </>
                        );
                      })}
                    </OwlCarousel>
                  </div>
                ) : (
                  <p className="no-review">
                    No reviews for this salon just yet
                  </p>
                )}
              </Col>
              <div className="add-review-text">
                <Link
                  to={`/leavereview/${Encryptedid(salonDetail?.salon_slug)}`}
                  className="RecifeDisplay"
                >
                  Leave a Review
                </Link>
              </div>
            </Row>
          </Container>
        </section>

        {/* What our clients say end */}

        {/* Brand Used section start  */}

        <section className="brand-user">
          <Container>
            <Row>
              <Col lg={10} className="mx-auto">
                <div className="brand-head-txt">
                  <h2 className="RecifeDisplay common-heading">Brand Used</h2>
                </div>
                <Row className="justify-content-center">
                  {salonDetail?.brand?.map((item, index) => {
                    const findImg = statics?.brand?.find(
                      (data) => item == data.title
                    );

                    return (
                      <Col lg={4} key={index}>
                        <div className="brand-user-logo">
                          <img
                            className="brand-logo-top"
                            src={
                              findImg?.brand_logo != "/brand/" &&
                                findImg?.brand_logo
                                ? BASE_URL + findImg?.brand_logo
                                : require("../assets/images/nouveau-trans.png")
                            }
                          />
                        </div>
                      </Col>
                    );
                  })}
                </Row>
              </Col>
            </Row>
          </Container>
        </section>

        {/* Brand Used section End  */}

        {/* follow social icon start  */}
        <section className="follow-social">
          <Container>
            <Row>
              <Col lg={10} className="mx-auto">
                {salonDetail?.subscription >= 2 && (
                  <div className="follow-icon-contin">
                    {salonDetail?.facebook_url && (
                      <div className="follow-icon-box">
                        <Link to={salonDetail?.facebook_url} target="_blank">
                          {" "}
                          <img
                            src={require("../assets/images/social/facebook.png")}
                          />
                        </Link>
                      </div>
                    )}
                    {salonDetail?.insta_url && (
                      <div className="follow-icon-box">
                        <Link to={salonDetail?.insta_url} target="_blank">
                          {" "}
                          <img
                            src={require("../assets/images/social/instagram.png")}
                          />
                        </Link>
                      </div>
                    )}
                    {salonDetail?.youtube_url && (
                      <div className="follow-icon-box">
                        <Link to={salonDetail?.youtube_url} target="_blank">
                          {" "}
                          <img
                            src={require("../assets/images/social/youtube.png")}
                          />
                        </Link>
                      </div>
                    )}
                    {salonDetail?.twitter_url && (
                      <div className="follow-icon-box">
                        <Link to={salonDetail?.twitter_url} target="_blank">
                          {" "}
                          <img
                            src={require("../assets/images/social/twitter.png")}
                          />
                        </Link>
                      </div>
                    )}
                    {salonDetail?.booking_url && (
                      <div className="follow-icon-box">
                        <Link to={salonDetail?.booking_url} target="_blank">
                          {" "}
                          <img
                            src={require("../assets/images/social/calender.png")}
                          />
                        </Link>
                      </div>
                    )}
                    {salonDetail?.tiktok_url && (
                      <div className="follow-icon-box">
                        <Link to={salonDetail?.tiktok_url} target="_blank">
                          {" "}
                          <img
                            src={require("../assets/images/social/tiktok.png")}
                          />
                        </Link>
                      </div>
                    )}
                  </div>
                )}

                <div className="brand-head-txt">
                  <h2 className="RecifeDisplay common-heading">
                    {salonDetail?.subscription != 1 &&
                      `Follow ${salonDetail?.business_name}`}
                  </h2>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
        {/* follow social icon End  */}

        {/* footer section start  */}

        {/* footer section end  */}
      </Layout>
    </>
  );
}
